import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const Garden = () => {
    return (
        <>
           <Header active="Products"/>
            <HeroSection />

            {/* ------------------- */}

            <div className="row bgdotimg " id="LFMS">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">GARDEN DAKSHA</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        Municipal corporations are developing number of gardens in their jurisdiction area for their citizens. Once
                        development is completed there is a need to maintain these gardens by performing different works namely,
                        Garden, Civil, Electrical, Playing Equipment’s, and Garden Security. To maintain and complete the different
                        maintenance works Municipal Corporations are appointing different expert agencies. Currently there is no
                        any mechanism for monitoring agency wise assigned and completed works.

                    </p>
                    <p>
                        CDAT Pune, has approached to the Navi Mumbai and Pimpri Chinchwad Municipal Corporations to design
                        the mechanism for monitoring the appointed agency wise works. In this concern we designed proposed
                        solution and deployed in NMMC and PCMC.
                    </p>
                    <p>
                        The proposed solution is having different work modules to monitor
                        the daily basis appointed agencies and their works.
                    </p>
                </div>
            </div>
            {/* <!--end of LFMS sopi--> */}

            <div className="row  px-3 col-lg-10 mx-auto">

                <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4 border ">
                    <div className="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/module.gif" alt="..." style={{width:'55px'}}  />
                        <p> <b>Garden Work Module</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4 border ">
                    <div className="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/module.gif" alt="..." style={{width:'55px'}}  />
                        <p> <b>Civil Work Module</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4 border ">
                    <div className="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/module.gif" alt="..." style={{width:'55px'}}  />
                        <p> <b>Electrical Work Module</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4 border">
                    <div className="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/module.gif" alt="..." style={{width:'55px'}}  />
                        <p> <b>Garden Security Work Module</b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4 border">
                    <div className="flex-grow-1 ms-3 m-auto">
                    <img src="Assets/Images/module.gif" alt="..." style={{width:'55px'}}  />
                        <p><b> Playing Equipment Module</b></p>
                    </div>
                </div>



            </div>

            <hr className="mx-5 bg-danger mt-5"/>


                <div className="row py-5 px-3" id="key">
                    <div className="col-12 col-md-10 mx-auto py-1">
                        <h3 className="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                        <div className="zoom-in-out-box2 m-auto">
                            <hr />
                        </div>
                    </div>

                    <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                        <div className="flex-grow-1 ms-3 m-auto">
                            <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                            <p> <b>Comprehensive KPI Monitoring</b> </p>
                        </div>
                    </div>

                    <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                        <div className="flex-grow-1 ms-3 m-auto">
                            <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                            <p> <b>Efficient Task Assignment</b> </p>
                        </div>
                    </div>

                    <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                        <div className="flex-grow-1 ms-3 m-auto">
                            <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                            <p> <b>Defaulter Identification and Penalty Calculation</b> </p>
                        </div>
                    </div>

                    <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                        <div className="flex-grow-1 ms-3 m-auto">
                            <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                            <p> <b>Real-time Progress Tracking</b></p>
                        </div>
                    </div>

                    <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                        <div className="flex-grow-1 ms-3 m-auto">
                            <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                            <p><b>Work Duplication Prevention</b></p>
                        </div>
                    </div>

                    <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                        <div className="flex-grow-1 ms-3 m-auto">
                            <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                            <p><b>Geotagged Location Tracking for Enhanced Supervision</b></p>
                        </div>
                    </div>



                </div>
                {/* <!--end of row key features--> */}


                <div className="row" id="Brochure">
                    <div className="col-12 charchole py-5">
                        <h4 className="text-center white">
                            <b>
                                "To acquire further information regarding the product."
                            </b>
                        </h4>
                        <p className="white  text-center py-3">
                            Explore the following details and specifications.
                        </p>

                        <div className="py-4 text-center">
                            <a onClick={() => window.open('http://cdat.co.in/doc/Garden_maintenance_Monitoring_System.pdf')}
                                // href="http://cdat.co.in/doc/WATER_SUPPLY_MAITENANCE_BROUCHERS.pdf"
                                className="shrink-border text-decoration-none"
                                target="_blank">Explore Brochure</a>

                        </div>
                    </div>
                </div>
                {/* <!--end of Brochure row--> */}



                <Footer />

            </>
            )
}