import { useNavigate } from "react-router-dom"
import { useEffect, useState } from 'react';
import mixitup from 'mixitup'

export const FEATURED_PORTFOLIO = () => {

    const [type, settype] = useState('All')

    const navigate = useNavigate()
    useEffect(() => {
        mixitup(".items", {
            selectors: {
                target: ".item",
            },
            animation: {
                duration: 500
            }
        });
    }, []);


    return (
        <>
            <div className="row bg-dark  photo-list" id="portfollio">

                <div className="col-10 text-center pb-1 pt-5 m-auto white">
                    <h3 className="text-center"> <b>FEATURED PORTFOLIO</b> </h3>
                    <p className="pt-3 silver pb-3">This is the gallery, which shows some of our work.</p>
                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="col-12">
                    <div className="row  mx-auto col-md-8 pt-5 ">
                        <div className="col-6 col-md-3" >
                            <span onClick={(() => settype("All"))}
                                className={type == "All" ? "control mixitup-control portfolio_width actives btn btn-light btn-block btn-sm fw-bold py-2" : "control mixitup-control portfolio_width  btn btn-light btn-block btn-sm fw-bold py-2"} data-filter="all"><i
                                    className="fa-solid fa-border-all mx-1 "></i> All</span>
                        </div>

                        <div className="col-6 col-md-3">
                            <span onClick={(() => settype("Dashboard"))}
                                className={type == "Dashboard" ? "control mixitup-control portfolio_width actives btn btn-light btn-block btn-sm fw-bold py-2" : "control mixitup-control portfolio_width  btn btn-light btn-block btn-sm fw-bold py-2"}
                                data-filter=".dashboard"><i className="fa-solid fa-desktop mx-1"></i> Dashboard </span>
                        </div>

                        <div className="col-6 col-md-3">
                            <span onClick={(() => settype("Mobile"))}
                                className={type == "Mobile" ? "control mixitup-control portfolio_width actives btn btn-light btn-block btn-sm fw-bold py-2" : "control mixitup-control portfolio_width  btn btn-light btn-block btn-sm fw-bold py-2"}
                                data-filter=".mobile_app"> <i className="fa-solid fa-mobile-screen-button mx-1"></i> Mobile Applications</span>
                        </div>

                        <div className="col-6 col-md-3">
                            <span onClick={(() => settype("Websites"))}
                                className={type == "Websites" ? "control mixitup-control portfolio_width actives btn btn-light btn-block btn-sm fw-bold py-2" : "control mixitup-control portfolio_width  btn btn-light btn-block btn-sm fw-bold py-2"}
                                data-filter=".website"> <i className="fa-solid fa-globe mx-1"></i> Websites</span>
                        </div>
                    </div>

                    <div className="items row p-2 p-lg-5">

                        <div className="col-12 col-md-4 item mobile_app ">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="  Assets/Images/Home/Mobile/gkmarket_mobile-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/GlobalKisanMarket?ScreenName=Global Kisan market - ICT  Model&Name=Products')}>
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>

                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Global Kisan Market
                                        <br />
                                        <span className=""> Mobile Application</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/mrms-01.jpg" alt="Avatar" className="image_hover" />
                                    <div className="img-overlay">
                                        <a href="javascript:void(0)"
                                            onClick={() => navigate('/Mrms?ScreenName=Medical Reimbursement Management System')}
                                        >
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </a>
                                    </div>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Medical Reimbursement Management System
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item website mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/websites/cdat_infotech-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/WebsiteDevelopment?ScreenName=Website Development&Name=Services')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>

                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">CDAT Infotech
                                        <br />
                                        <span className="">Website</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item mobile_app mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/Mobile/edpr-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/MobileAppDevelopment?ScreenName=Mobile Development&Name=Services')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>

                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Watershed Detail Project Report
                                        <br />
                                        <span className=""> Mobile Application</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item mobile_app mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/Mobile/gayatri_mobile-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/AgricultureERP?ScreenName=Agriculture Fertilizer Manufacturing & Marketing ERP&Name=Products')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>

                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Gayatri Micro Elements & Chemicals
                                        <br />
                                        <span className=""> Mobile Application</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>



                        <div className="col-12 col-md-4 item mobile_app mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/Mobile/water_mobile-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/WaterSupply?ScreenName=Monitoring of Water Supply (O/M) Work&Name=Products')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Monitoring of Water Supply (O/M) Work
                                        <br />
                                        <span className=""> Mobile Application</span>

                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item mobile_app mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/Mobile/assets_mobile-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/Ams?ScreenName=Asset Management System&Name=Products')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold "> Asset Management System
                                        <br />
                                        <span className=""> Mobile Application</span>

                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item mobile_app mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/Mobile/national_health_mission-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/HealthCibil?ScreenName=HEALTH CIBIL&Name=Products')}>
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold "> National Health Mission
                                        <br />
                                        <span className=""> Mobile Application</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item mobile_app mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/Mobile/samarth_mobile-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/ERP?ScreenName=Manufacturing and  Service ERP&Name=Products')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold "> Manufacturing and Service Industry ERP
                                        <br />
                                        <span className=""> Mobile Application</span>

                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>


                        <div className="col-12 col-md-4 item mobile_app mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/Mobile/placewell_mobile-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/SmartLaborApplication?ScreenName=Smart Labor Application&Name=Products')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold "> Placewell Industrial Services Pvt Ltd
                                        <br />
                                        <span className=""> Mobile Application</span>

                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item mobile_app mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/Mobile/garden_mobile-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/Garden?ScreenName=Monitoring of Garden (O/M) Work&Name=Products')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold "> GARDEN DAKSHA
                                        <br />
                                        <span className=""> Mobile Application</span>

                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item mobile_app mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/Mobile/stp_mobile-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/STP?ScreenName=Monitoring of STP (O/M) Work&Name=Products')}>
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold "> Monitoring of STP (O/M) Work
                                        <br />
                                        <span className=""> Mobile Application</span>

                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item mobile_app mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/Mobile/road_mobile-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/RoadWork?ScreenName=Monitoring of Road (O/M) Work&Name=Products')}>
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold "> Monitoring of Road (O/M) Work
                                        <br />
                                        <span className=""> Mobile Application</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>



                        <div className="col-12 col-md-4 item mobile_app mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/Mobile/sewerage_mobile-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/Sewerage?ScreenName=Monitoring of Sewerage (O/M) Work&Name=Products')} >
                                        <div className="img-overlay">
                                            <div className="text">
                                                <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold "> Monitoring of Sewerage (O/M) Work
                                        <br />
                                        <span className=""> Mobile Application</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item mobile_app mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/Mobile/ems_mobile-01.jpg" alt="Avatar" className="image_hover" />
                                    <div className="img-overlay">
                                        <a href="javascript:void(0)"
                                            onClick={() => navigate('/WebsiteDevelopment?ScreenName=Website Development&Name=Services')} >
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </a>
                                    </div>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Employee Monitoring System
                                        <br />
                                        <span className=""> Mobile Application</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item mobile_app mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/Mobile/inroad_mobile-01.jpg" alt="Avatar" className="image_hover" />
                                    <div className="img-overlay">
                                        <a href="javascript:void(0)"
                                            onClick={() => navigate('/WebsiteDevelopment?ScreenName=Website Development&Name=Services')} >
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </a>
                                    </div>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Rubber Plantation
                                        <br />
                                        <span className=""> Mobile Application</span>

                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item mobile_app mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/Mobile/amchi_mulgi_mobile-01.jpg" alt="Avatar" className="image_hover" />

                                    <div className="img-overlay">
                                        <a href="javascript:void(0)"
                                            onClick={() => navigate('/WebsiteDevelopment?ScreenName=Website Development&Name=Services')} >
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </a>
                                    </div>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Public Grievance In Aamchi Mulgi Scheme
                                        <br />
                                        <span className=""> Mobile Application</span>

                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>


                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/sopi-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/Sopi?ScreenName=System of Pension Illustration&Name=Products')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">System of Pension
                                        Illustration
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>


                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/ams-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/Ams?ScreenName=Asset Management System&Name=Products')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>

                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Asset Management System
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/lfms-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/Lfms?ScreenName=Letter and File Management System&Name=Products')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Letter & File Management System
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/online_letter_system-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/Etapal?ScreenName=Online Letter System&Name=Products')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Online Letter System
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item website mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/websites/placewell_web-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/SmartLaborApplication?ScreenName=Smart Labor Application&Name=Products')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Placewell Industrial Services Pvt Ltd
                                        <br />
                                        <span className="">Website</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item website mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/websites/gkmarket-web-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/GlobalKisanMarket?ScreenName=Global Kisan market - ICT  Model&Name=Products')}>
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Global Kisan Market
                                        <br />
                                        <span className="">Website</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item website mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/websites/globalhive-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/WebsiteDevelopment?ScreenName=Website Development&Name=Services')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Globalhive
                                        <br />
                                        <span className="">Website</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item website mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/websites/icds-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/WebsiteDevelopment?ScreenName=Website Development&Name=Services')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Integrated Child Development Services
                                        <br />
                                        <span className="">Website</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item website mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/websites/ggci-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/WebsiteDevelopment?ScreenName=Website Development&Name=Services')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Global Green Credit Initiative
                                        <br />
                                        <span className="">Website</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/medicine_stock_mng_system-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/MedicineStockManageSystem?ScreenName=Medicine Stock Management System&Name=Products')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Medicine Stock Management System
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>


                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/placewell-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/SmartLaborApplication?ScreenName=Smart Labor Application&Name=Products')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>

                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Smart Labor Application
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/amchi_mulgi_web-01.png" alt="Avatar" className="image_hover" />
                                    <div className="img-overlay">
                                        <a href="javascript:void(0)"
                                            onClick={() => navigate('/WebsiteDevelopment?ScreenName=Website Development&Name=Services')} >
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </a>
                                    </div>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Public Grievance In Aamchi Mulgi Scheme
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/ems_Web-01.png" alt="Avatar" className="image_hover" />
                                    <div className="img-overlay">
                                        <a href="javascript:void(0)"
                                            onClick={() => navigate('/WebsiteDevelopment?ScreenName=Website Development&Name=Services')} >
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </a>
                                    </div>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Employee Monitoring System
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/eyrubberplant-01.png" alt="Avatar" className="image_hover" />
                                    <div className="img-overlay">
                                        <a href="javascript:void(0)"
                                            onClick={() => navigate('/WebsiteDevelopment?ScreenName=Website Development&Name=Services')} >
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </a>
                                    </div>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Rubber Plantation
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/sewerage_daksh-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/Sewerage?ScreenName=Monitoring of Sewerage (O/M) Work&Name=Products')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Monitoring of Sewerage (O/M) Work
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/road_daksh-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/RoadWork?ScreenName=Monitoring of Road (O/M) Work&Name=Products')}>
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Monitoring of Road (O/M) Work
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/garden_daksh-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/Garden?ScreenName=Monitoring of Garden (O/M) Work&Name=Products')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Monitoring of Garden (O/M) Work
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/water_daksh-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/WaterSupply?ScreenName=Monitoring of Water Supply (O/M) Work&Name=Products')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Monitoring of Water Supply (O/M) Work
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>



                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/stp_daksh-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/STP?ScreenName=Monitoring of STP (O/M) Work&Name=Products')}>
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Monitoring of STP (O/M) Work
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/ptms-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/PTMS?ScreenName=Project Tracking & Monitoring System&Name=Products')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Project Tracking and Monitoring System
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/samarth-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/ERP?ScreenName=Manufacturing and  Service ERP&Name=Products')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Manufacturing and Service Industry ERP
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/gayatri-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/AgricultureERP?ScreenName=Agriculture Fertilizer Manufacturing & Marketing ERP&Name=Products')} >
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Agriculture and Manufacturing Industry ERP
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>


                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/gkmarket-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/GlobalKisanMarket?ScreenName=Global Kisan market - ICT  Model&Name=Products')}>
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Global Kisan Market
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/gazzet-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/PoliceGazette?ScreenName=Police Gazette Management System&Name=Products')}>
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Police Gazette Management System
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/health_cibil-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/HealthCibil?ScreenName=HEALTH CIBIL&Name=Products')}>
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Health Cibil
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/hrdms-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/HRMSystem?ScreenName=Human Resource Management System&Name=Products')}>
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Human Resource Management System
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-4 item dashboard mix">
                            <div className="p-3">
                                <div className="single-img">
                                    <img src="Assets/Images/Home/nhm-01.jpg" alt="Avatar" className="image_hover" />
                                    <a href="javascript:void(0)"
                                        onClick={() => navigate('/NursingHome?ScreenName=Nursing Home Registration Application&Name=Products')}>
                                        <div className="img-overlay">
                                            <div className="text"> <i className="fa-solid fa-info fw-bold fs-6 border fa_info"></i></div>
                                        </div>
                                    </a>
                                </div>
                                <div className="silver_bg text-center">
                                    <br />
                                    <span className="text-white">.</span>
                                    <p className="fw-bold ">Nursing Home Registration System
                                        <br />
                                        <span className="">Dashboard</span>
                                    </p>
                                    <p className="pt-1"></p>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

            </div>
        </>
    )
}