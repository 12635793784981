import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const HRMSystem =()=>{
    return(
        <>
           <Header active="Products"/>
            <HeroSection/>

            {/* --------------------- */}


            <div class="row bgdotimg " id="AMS">
            <div class="col-12 col-md-10 mx-auto py-4">
                <h3 class="fw-bold colorcharchol text-center">Human Resource Management System </h3>

                <div class="zoom-in-out-box2 m-auto">
                    <hr/>
                </div>
            </div>
            <div class="col-12 col-md-10 mx-auto py-3 text-center">
                <p>
                    The Human Resource Management System (HRMS) is a powerful and versatile solution designed to streamline and optimize all aspects of HR operations within an organization. From employee information management and recruitment to payroll processing, performance appraisal, and compliance monitoring, HRMS simplifies complex HR tasks, enhances data accuracy, and empowers both HR professionals and employees with self-service capabilities. With robust analytics and reporting tools, HRMS facilitates data-driven decision-making and promotes greater efficiency, security, and compliance, making it an indispensable tool for managing and nurturing an organization's most valuable asset – its workforce.
                </p>
            </div>
        </div>
        {/* <!--end of AMS sopi--> */}
       

        <div class="row py-5 px-3" id="key">
            <div class="col-12 col-md-10 mx-auto py-1">
                <h3 class="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                <div class="zoom-in-out-box2 m-auto">
                    <hr/>
                </div>
            </div>

            <div class="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                <div class="flex-grow-1 ms-3 m-auto">
                <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                    <p> <b>Employee Information Management</b> </p>
                </div>
            </div>

            <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                <div class="flex-grow-1 ms-3 m-auto">
                <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                    <p> <b>Recruitment and Onboarding</b> </p>
                </div>
            </div>

            <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                <div class="flex-grow-1 ms-3 m-auto">
                <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                    <p> <b>Statutory Compliance</b> </p>
                </div>
            </div>

            <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                <div class="flex-grow-1 ms-3 m-auto">
                <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                    <p> <b>Time and Attendance Tracking</b></p>
                </div>
            </div>

            <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                <div class="flex-grow-1 ms-3 m-auto">
                <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                    <p><b>Performance Management</b></p>
                </div>
            </div>

            <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                <div class="flex-grow-1 ms-3 m-auto">
                <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                    <p><b>Payroll and Benefits Administration</b></p>
                </div>
            </div>
 
            <div class="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                <div class="flex-grow-1 ms-3 m-auto">
                <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                    <p><b>Training and Development</b></p>
                </div>
            </div>
            

        </div>
        {/* <!--end of row key features--> */}


        <div class="row" id="Brochure">
            <div class="col-12 charchole py-5">
                <h4 class="text-center white">
                    <b>
                        "To acquire further information regarding the product."
                    </b>
                </h4>
                <p class="white  text-center py-3">
                    Explore the following details and specifications.
                </p>

                <div class="py-4 text-center">
                    <a onClick={()=> window.open('http://cdat.co.in/doc/HRMS.pdf')}
                    // href="http://cdat.co.in/doc/HRMS.pdf"
                     class="shrink-border text-decoration-none"
                        target="_blank">Explore Brochure</a>

                </div>
            </div>
        </div>
        {/* <!--end of Brochure row--> */}


      




            
            <Footer/>
        </>
    )
}