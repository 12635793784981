export const GaneshChaturthi =()=>{
    return(
        <>
             <div className="row p-5" id="event_photo_second">

<div className="col-12 col-md-10 mx-auto py-4">
  <h3 className="fw-bold colorcharchol text-center">Ganesh Chaturthi</h3>

  <div className="zoom-in-out-box2 m-auto">
      <hr/>
  </div>
</div>

<div className=" col-12 col-md-6 col-lg-3 ds">
  <div className="gallery-item mb-3" >
    <div>
      <a href="Assets/Images/event/ganesh1.jpg" className="thumbnail">
        <span>
          <img src="Assets/Images/event/ganesh1.jpg" alt="" />
        </span>
      </a>
    </div>
  </div>
</div>

<div className=" col-12 col-md-6 col-lg-3 ds">
  <div className="gallery-item mb-3" >
    <div>
      <a href="Assets/Images/event/ganesh-2.jpg" className="thumbnail">
        <span>
          <img src="Assets/Images/event/ganesh-2.jpg" alt="" />
        </span>
      </a>
    </div>
  </div>
</div>

<div className=" col-12 col-md-6 col-lg-3 ds">
  <div className="gallery-item mb-3" >
    <div>
      <a href="Assets/Images/event/ganesh-3.jpg" className="thumbnail">
        <span>
          <img src="Assets/Images/event/ganesh-3.jpg" alt="" />
        </span>
      </a>
    </div>
  </div>
</div>

<div className=" col-12 col-md-6 col-lg-3 ds">
  <div className="gallery-item mb-3" >
    <div>
      <a href="Assets/Images/event/ganesh4.jpg" className="thumbnail">
        <span>
          <img src="Assets/Images/event/ganesh4.jpg" alt="" />
        </span>
      </a>
    </div>
  </div>
</div>

</div>
        </>
    )
}