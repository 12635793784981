import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const RoadWork = () => {
    return (
        <>
           <Header active="Products"/>
            <HeroSection />

            {/* ------------------------------- */}

            <div className="row bgdotimg " id="LFMS">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Road Work</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        NMMC DAKSHA is a web and mobile-based solution developed for comprehensive contract management of road maintenance in Navi Mumbai by the Navi Mumbai Municipal Corporation (NMMC). It focuses on effective Key Performance Indicator (KPI) monitoring with benchmark and penalty values for daily contractor progress assessment. The system streamlines work image capture with real-time geotagging from contractors and provides supervisory officers with tools to validate uploaded images. Additionally, a dashboard offers KPI-wise daily progress tracking and penalty calculations for defaulters.

                    </p>
                </div>
            </div>
            {/* <!--end of LFMS sopi--> */}

            <div className="row py-5 px-3" id="key">
                <div className="col-12 col-md-10 mx-auto py-1">
                    <h3 className="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Real-Time KPI Monitoring</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Efficient Work Documentation</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Supervisory Oversight G</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Comprehensive Dashboard </b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Penalty Management </b></p>
                    </div>
                </div>

            </div>
            {/* <!--end of row key features--> */}

            <div className="row" id="Brochure">
                <div className="col-12 charchole py-5">
                    <h4 className="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p className="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div className="py-4 text-center">
                        <a onClick={() => window.open('http://cdat.co.in/doc/ROAD_MAITENANCE_BROUCHERS.pdf')}
                            // href="http://cdat.co.in/doc/ROAD_MAITENANCE_BROUCHERS.pdf"
                            className="shrink-border text-decoration-none"
                            target="_blank">Explore Brochure</a>

                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}