import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const ERP = () => {
    return (
        <>
          <Header active="Products"/>
            <HeroSection />

            {/* ------------------------------- */}


            <div className="row bgdotimg " id="LFMS">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Manufacturing and Service Industry ERP</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        Samarth ERP is a comprehensive and integrated solution for both manufacturing and service
                        industries. System manages customer enquiries, purchase orders, and sales processes more
                        effectively. key features such as master data management, dynamic dashboards, purchase order flow,
                        production management, inventory control, and preventive maintenance. The customer portal where
                        clients can log complaints, track their status, and receive notifications.


                    </p>
                    <p>
                        The convenience of automatic serial number generation for products based on different models. This
                        can save time and reduce the risk of errors.

                    </p>
                    <p>
                        The ERP system optimizes production flows, tracks product models, manages third-party finish goods,
                        and automates serial number generation, resulting in smoother manufacturing processes and higher
                        product quality.

                    </p>
                </div>
            </div>
            {/* <!--end of LFMS sopi--> */}

            <div className="row py-5 px-3" id="key">
                <div className="col-12 col-md-10 mx-auto py-1">
                    <h3 className="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Sales Enablement</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Administrator Empowerment</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Service Management</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Customer Engagement</b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Automated Serial Number Generation</b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Manages third-party finish goods</b></p>
                    </div>
                </div>
            </div>
            {/* <!--end of row key features--> */}

            <div className="row" id="Brochure">
                <div className="col-12 charchole py-5">
                    <h4 className="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p className="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div className="py-4 text-center">
                        <a onClick={()=> window.open('http://cdat.co.in/doc/MANUFACTURING_AND_SERVICE_ERP_BROUCHERS.pdf')}
                        // href="http://cdat.co.in/doc/MANUFACTURING_AND_SERVICE_ERP_BROUCHERS.pdf"
                            className="shrink-border text-decoration-none" target="_blank">Explore Brochure</a>

                    </div>
                </div>
            </div>
            {/* <!--end of Brochure row--> */}



            <Footer />
        </>
    )
}