import { useNavigate, useSearchParams } from "react-router-dom"

export const HeroSection = () => {
  const navigate = useNavigate()
  const [searchparams] = useSearchParams()
  const ScreenName = searchparams.get('ScreenName')
  const Name = searchparams.get('Name')

  return (
    <div className="row  herobg1" id="herosection">
      <div className="col-12 whop ">
        <div className=" container container-md-fluid">
          <div className="row ">

            <div className="col-auto m-auto ">
              <h2 className="white text-center"> <b>{ScreenName}</b> </h2>
              <nav aria-label="breadcrumb " className="first  d-md-flex">
                <ol className="breadcrumb indigo lighten-6 first-1 shadow-lg ">
                  <li><a className="black-text text-uppercase ms-1 " href="javascript:void(0) "   onClick={() => navigate('/MainHomePage')} ><span>home</span></a> <i
                    className="fas fa-angle-double-right silver"></i> </li>
                  {
                    Name &&
                    <li><a className="black-text text-uppercase"
                      href="javascript:void(0)"><span>{Name}</span></a> <i
                        className="fas fa-angle-double-right silver"></i></li>
                  }
                  <li className="breadcrumb-item font-weight-bold"><a className="black-text text-uppercase  "
                    href="javascript:void(0)"><span>{ScreenName}</span></a></li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}