import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const AgricultureERP = () => {
    return (
        <>
          <Header active="Products"/>
            <HeroSection />

            {/* ---------------------------- */}



            <div className="row bgdotimg " id="LFMS">
                <div className="col-12 col-md-10 mx-auto py-4">
                    <h3 className="fw-bold colorcharchol text-center">Agriculture and Manufacturing Industry ERP</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>
                <div className="col-12 col-md-10 mx-auto py-3 text-center">
                    <p>
                        This versatile web and Android mobile application streamline business processes across departments. It offers
                        accessibility through web browsers and Android devices, utilizing a centralized database for data consistency.
                        Functioning as an ERP, it covers Sales, Production, Store, and Admin Departments.
                    </p>
                    <p>
                        It manages master data, batch-wise product information, and material inventory. Employees mark attendance with
                        photos and track activities via geo-location. Sales targets are set and monitored, while officers update
                        achievements against the assigned order targets. Monthly targets are overseen, and District Officers assign
                        targets to their teams. Real-time updates include geo-tagged photos for various activities viz. Field Day,
                        Filed Visit, Demonstration, Farmer Meeting.

                    </p>
                    <p>
                        The system handles leave requests, manages expired products, and monitors inventory. Dealer billing, cash
                        discounts, and employee expenses are tracked. This system promotes efficiency, transparency, and
                        interdepartmental coordination for enhanced business management.

                    </p>
                </div>
            </div>
            {/* <!--end of LFMS sopi--> */}


            <div className="row py-5 px-3" id="key">
                <div className="col-12 col-md-10 mx-auto py-1">
                    <h3 className="fw-bold colorcharchol text-center">KEY FEATURES</h3>

                    <div className="zoom-in-out-box2 m-auto">
                        <hr />
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Complete ERP for Multiple Departments</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Daily Attendance and Activity Monitoring</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Sales Target Management</b> </p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p> <b>Monthly Target and Achievement Tracking</b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Field Activity Tracking</b></p>
                    </div>
                </div>

                <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
                    <div className="flex-grow-1 ms-3 m-auto">
                        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
                        <p><b>Material Inventory Monitoring</b></p>
                    </div>
                </div>



            </div>
            {/* <!--end of row key features--> */}


            <div className="row" id="Brochure">
                <div className="col-12 charchole py-5">
                    <h4 className="text-center white">
                        <b>
                            "To acquire further information regarding the product."
                        </b>
                    </h4>
                    <p className="white  text-center py-3">
                        Explore the following details and specifications.
                    </p>

                    <div className="py-4 text-center">
                        <a onClick={()=> window.open('http://cdat.co.in/doc/AGRICULTURE_FERTILIZER_AND_MANUFACTURING_ERP_BROUCHERS.pdf')}
                        // href="http://cdat.co.in/doc/AGRICULTURE_FERTILIZER_AND_MANUFACTURING_ERP_BROUCHERS.pdf"
                            className="shrink-border text-decoration-none" target="_blank">Explore Brochure</a>
                    </div>
                </div>
            </div>
            {/* <!--end of Brochure row--> */}

            <Footer />
        </>
    )
}