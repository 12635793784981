import React, { useState } from 'react'
import { AllRoutes } from './AllRoutes'

const App = () => {
  const [loading, setLoading] = useState(true);
  const spinner = document.getElementById('spinner');
  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLoading(false);
    }, 3000);
  }
  return (
    !loading &&
    (
      <AllRoutes />
    )
  )
}

export default App