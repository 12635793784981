import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"



export const Header = ({ active }) => {
    const navigate = useNavigate()

    return (
        <>
            <div className="row " onLoad={() => window.scrollTo(0, 0)}>
                <div className="col-12 ">

                    <nav className="navbar navbar-expand-lg navbar-light bg-light navbar_fixed box-s">
                        <div className="container-fluid">

                            <a href="javascript:void(0)"
                                onClick={() => navigate('/')}>
                                <img src="Assets/Images/logo_color-01.png" alt="img not found" className="navlogo" /></a>
                            <span class="headline "> Center for Development of Advance Technology Pvt.Ltd.</span>


                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav ms-auto mb-2 mb-lg-2">
                                    {/* <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#">Home</a>
                            </li> */}
                                    {/* <li>
                                        <a href="javascript:void(0)" onClick={() => navigate('/')} className="text-decoration-none "  
                                       
                                        >Home</a>
                                    </li> */}
                                    <li>
                                        <Link to="/"
                                            className={active == "Home" ? "active" : ""}
                                        >Home</Link>
                                    </li>

                                    <li>
                                        <Link
                                            className={active == "About" ? "active dropdown-toggle" : "dropdown-toggle"}
                                        // className="text-decoration-none dropdown-toggle"
                                        >About</Link>

                                        <ul className="navbar-dropdown">
                                            <li>
                                                <Link to="/WhoWeAre?ScreenName=Who We Are&Name=About"
                                                    // onClick={() => navigate('/WhoWeAre?ScreenName=Who We Are&Name=About')}
                                                    className="text-decoration-none ">Who We Are</Link>
                                            </li>
                                            <li>
                                                <Link to="/WhatWeDo?ScreenName=What We Do&Name=About"

                                                    // onClick={() => navigate('/WhatWeDo?ScreenName=What We Do&Name=About')}
                                                    className="text-decoration-none title-top"
                                                >What We Do</Link>
                                            </li>
                                            <li>
                                                <Link to="/WhyUs?ScreenName=Why Us&Name=About"
                                                    // onClick={() => navigate('/WhyUs?ScreenName=Why Us&Name=About')}
                                                    className="text-decoration-none">Why Us</Link>
                                            </li>
                                            <li>
                                                <Link to="/Culture?ScreenName=Culture&Name=About"
                                                    // onClick={() => navigate('/Culture?ScreenName=Culture&Name=About')}
                                                    className="text-decoration-none">Culture</Link>
                                            </li>
                                            <li>
                                                <Link to="/Events?ScreenName=Events&Name=About"
                                                    // onClick={() => navigate('/Events?ScreenName=Events&Name=About')}
                                                    className="text-decoration-none">Events</Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <Link
                                            className={active == "Expertise" ? "active dropdown-toggle" : "dropdown-toggle"}>Expertise</Link>
                                        <ul className="navbar-dropdown">
                                            <li>
                                                <Link to="/Egovernance?ScreenName=E-Governance&Name=Expertise"
                                                    // onClick={() => navigate('/Egovernance?ScreenName=E-Governance&Name=Expertise')} 
                                                    className="text-decoration-none">E-Governance</Link>
                                            </li>
                                            <li>
                                                <Link to="/Healthcare?ScreenName=Healthcare&Name=Expertise"
                                                    // onClick={() => navigate('/Healthcare?ScreenName=Healthcare&Name=Expertise')} 
                                                    className="text-decoration-none">Healthcare</Link>
                                            </li>
                                            <li>
                                                <Link to="/ManufacturingAndServicesIndustry?ScreenName=Manufacturing and Services Industry&Name=Expertise"
                                                    // onClick={() => navigate('/ManufacturingAndServicesIndustry?ScreenName=Manufacturing and Services Industry&Name=Expertise')} 
                                                    className="text-decoration-none">Manufacturing &
                                                    Services Industry</Link>
                                            </li>
                                            <li>
                                                <Link to="/FinancialServices?ScreenName=Financial Services&Name=Expertise"
                                                    // onClick={() => navigate('/FinancialServices?ScreenName=Financial Services&Name=Expertise')} 
                                                    className="text-decoration-none">Financial Services</Link>
                                            </li>
                                            <li>
                                                <Link to="/E_Commerce?ScreenName=E-Commerce&Name=Expertise"
                                                    // onClick={() => navigate('/E_Commerce?ScreenName=E-Commerce&Name=Expertise')} 
                                                    className="text-decoration-none">E-Commerce</Link>
                                            </li>
                                            <li>
                                                <Link to="/HRMS?ScreenName=Human Resource Management System&Name=Expertise"
                                                    // onClick={() => navigate('/HRMS?ScreenName=Human Resource Management System&Name=Expertise')}

                                                    className="text-decoration-none">HRMS</Link>
                                            </li>
                                            <li>
                                                <Link to="/Agriculture?ScreenName=Agriculture&Name=Expertise"
                                                    // onClick={() => navigate('/Agriculture?ScreenName=Agriculture&Name=Expertise')} 
                                                    className="text-decoration-none">Agriculture</Link>
                                            </li>

                                        </ul>
                                    </li>

                                    <li>
                                        <Link
                                            className={active == "Services" ? "active dropdown-toggle" : "dropdown-toggle"}>
                                            Services</Link>
                                        <ul className="navbar-dropdown dm_height">
                                            <li>
                                                <Link to="/BiSolutions?ScreenName=Business Intelligence Solutions&Name=Services"
                                                    // onClick={() => navigate('/BiSolutions?ScreenName=Business Intelligence Solutions&Name=Services')}

                                                    className="text-decoration-none">BI solutions</Link>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/DataAnalytics?ScreenName=Data Analytics&Name=Services')} className="text-decoration-none">Data Analytics</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/WebsiteDevelopment?ScreenName=Website Development&Name=Services')} className="text-decoration-none">Web Development</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/MobileAppDevelopment?ScreenName=Mobile Development&Name=Services')} className="text-decoration-none">Mobile Development</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/GraphicDesign?ScreenName=Graphic Design&Name=Services')} className="text-decoration-none">Graphic Design</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/CustomizedSolution?ScreenName=Customized Solutions&Name=Services')} className="text-decoration-none">Customized Solutions</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/ProductDevelopment?ScreenName=Product Development&Name=Services')} className="text-decoration-none">Product Development</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/ProductMaintenance?ScreenName=Product Maintenance&Name=Services')} className="text-decoration-none">Product Maintenance</a>
                                            </li>

                                        </ul>
                                    </li>


                                    <li>

                                        <Link
                                            className={active == "Products" ? "active dropdown-toggle" : "dropdown-toggle"}>
                                            Products</Link>

                                        <ul className="navbar-dropdown dm_height">

                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/Garden?ScreenName=GARDEN DAKSHA&Name=Products')} className="text-decoration-none"> GARDEN DAKSHA
                                                </a>
                                            </li>

                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/Sopi?ScreenName=System of Pension Illustration&Name=Products')} className="text-decoration-none">System of Pension
                                                    Illustration </a>
                                            </li>

                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/Mrms?ScreenName=Medical Reimbursement Management System&Name=Products')} className="text-decoration-none"> Medical
                                                    Reimbursement Management System</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/Ams?ScreenName=Asset Management System&Name=Products')} className="text-decoration-none">Asset Management System</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/Lfms?ScreenName=Letter and File Management System&Name=Products')} className="text-decoration-none">
                                                    Letter & File
                                                    Management System</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/Etapal?ScreenName=Online Letter System&Name=Products')} className="text-decoration-none">Online Letter System
                                                </a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/MedicineStockManageSystem?ScreenName=Medicine Stock Management System&Name=Products')} className="text-decoration-none">Medicine Stock
                                                    Management System </a>
                                            </li>

                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/PublicGrievance?ScreenName=Public Grievance Management System&Name=Products')} className="text-decoration-none">Public Grievance
                                                    Management System</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/SmartLaborApplication?ScreenName=Smart Labor Application&Name=Products')} className="text-decoration-none">Smart Labor
                                                    Application</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/Sewerage?ScreenName=Monitoring of Sewerage (O/M) Work&Name=Products')} className="text-decoration-none">Monitoring of Sewerage
                                                    (O/M) Work</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/RoadWork?ScreenName=Monitoring of Road (O/M) Work&Name=Products')}
                                                    className="text-decoration-none"> Monitoring of Road
                                                    (O/M) Work</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/STP?ScreenName=Monitoring of STP (O/M) Work&Name=Products')}
                                                    className="text-decoration-none">Monitoring of STP
                                                    (O/M) Work</a>
                                            </li>


                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/WaterSupply?ScreenName=Monitoring of Water Supply (O/M) Work&Name=Products')} className="text-decoration-none">Monitoring of Water Supply
                                                    (O/M) Work</a>
                                            </li>

                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/PTMS?ScreenName=Project Tracking & Monitoring System&Name=Products')} className="text-decoration-none"> Project Tracking &
                                                    Monitoring System</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/ERP?ScreenName=Manufacturing and  Service ERP&Name=Products')} className="text-decoration-none">Manufacturing &
                                                    Service ERP</a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/AgricultureERP?ScreenName=Agriculture Fertilizer Manufacturing & Marketing ERP&Name=Products')} className="text-decoration-none">Agriculture
                                                    Fertilizer Manufacturing & Marketing ERP</a>
                                            </li>

                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/GlobalKisanMarket?ScreenName=Global Kisan Market - ICT  Model&Name=Products')}
                                                    className="text-decoration-none">Global Kisan Market - ICT
                                                    Model</a>
                                            </li>


                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/PoliceGazette?ScreenName=Police Gazette Management System&Name=Products')} className="text-decoration-none">Police Gazette Management System</a>
                                            </li>

                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/HealthCibil?ScreenName=HEALTH CIBIL&Name=Products')} className="text-decoration-none">HEALTH CIBIL</a>
                                            </li>


                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/HRMSystem?ScreenName=Human Resource Management System&Name=Products')} className="text-decoration-none">Human Resource
                                                    Management System</a>
                                            </li>

                                            <li>
                                                <a href="javascript:void(0)"
                                                    onClick={() => navigate('/NursingHome?ScreenName=Nursing Home Registration Application&Name=Products')} className="text-decoration-none">Nursing Home
                                                    Registration Application</a>
                                            </li>

                                        </ul>
                                    </li>

                                    <li>
                                        {/* <a href="#!" className="text-decoration-none dropdown-toggle"> */}
                                        <Link
                                            className={active == "Clients" ? "active dropdown-toggle" : "dropdown-toggle"}>
                                            Clients </Link>
                                        <ul className="navbar-dropdown">
                                            <li>
                                                <Link to="/OurClients?ScreenName=Our Clients&Name=Clients"
                                                    // onClick={() => navigate('/OurClients?ScreenName=Our Clients&Name=Clients')} 
                                                    className="text-decoration-none">Our Clients</Link>
                                            </li>
                                            <li>
                                                <Link to="/ClientReviews?ScreenName=Clients Review&Name=Clients"
                                                    // onClick={() => navigate('/ClientReviews?ScreenName=Clients Review&Name=Clients')} 
                                                    className="text-decoration-none">Clients Review</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    {/* <Link to="/"
                                            className={active == "Home" ? "active" : ""} */}

                                    <li>
                                        <Link
                                            to="/Blog?ScreenName=Blogs"
                                            className={active == "Blog" ? "active" : ""}
                                        // onClick={() => navigate('/Blog?ScreenName=Blogs')} 
                                        >Blogs</Link>
                                    </li>

                                    <li>

                                        <Link to="/CareerPage?ScreenName=Careers"
                                            className={active == "Careers" ? "active" : ""}
                                        // onClick={() => navigate('/CareerPage?ScreenName=Careers')} 
                                        >Careers</Link>
                                    </li>

                                    <li>
                                        <Link to="/ContactPage?ScreenName=Contact"
                                            className={active == "Contact" ? "active" : ""}
                                        // onClick={() => navigate('/ContactPage?ScreenName=Contact')} 
                                        >Contact</Link>
                                    </li>

                                </ul>

                            </div>
                        </div>
                    </nav>

                </div>
            </div>
        </>
    )
}