import { Footer } from "../Footer"
import { Header } from "../Header"
import { HeroSection } from "../HeroSection"

export const NursingHome = () => {
    return (
        <>
          <Header active="Products"/>
            <HeroSection />

            {/* ------------------------- */}

            
    <div className="row bgdotimg " id="Nursing Home">
      <div className="col-12 col-md-10 mx-auto py-4">
        <h3 className="fw-bold colorcharchol text-center">Nursing Home Registration System</h3>

        <div className="zoom-in-out-box2 m-auto">
          <hr/>
        </div>
      </div>
      <div className="col-12 col-md-10 mx-auto py-3 text-center">
        <p>
          Ability for Hospital/nursing home to submit new business license application online, renew existing business
          license, close existing license (with restriction). Ability to manage effectively and efficiently business
          license processes, with upload documents. Ability to enter remainder and receive notifications of remainders.
          Ability to tailor the requirement based on the applicant business type. A comprehensive tool for managing,
          accepting, printing and maintaining the license issued online. Ability to upload spot verification report.
          Ability to route approval electronically and it would be preferred that approvals are routed to multiple
          approvers. Comprehensive set of tools that can be used to manage user access, security and track changes
          entered into system by user. To generate digitally signed online registration certificate or license. System
          provided online payment option for CDAT will provide support and help to integrate the payment gateway
          provided by CHS for online payment.
        </p>
      </div>
    </div>
    {/* <!--end of AMS sopi--> */}


    <div className="row py-5 px-3" id="key">
      <div className="col-12 col-md-10 mx-auto py-1">
        <h3 className="fw-bold colorcharchol text-center">KEY FEATURES</h3>

        <div className="zoom-in-out-box2 m-auto">
          <hr/>
        </div>
      </div>

      <div className="col-10 col-md-4 mx-auto text-center  box-sn mt-4">
        <div className="flex-grow-1 ms-3 m-auto">
        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
          <p> <b>New license activity</b> </p>
        </div>
      </div>

      <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
        <div className="flex-grow-1 ms-3 m-auto">
        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
          <p> <b>License renewal activity</b> </p>
        </div>
      </div>

      <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
        <div className="flex-grow-1 ms-3 m-auto">
        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
          <p> <b>Hospital listing </b> </p>
        </div>
      </div>

      <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
        <div className="flex-grow-1 ms-3 m-auto">
        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
          <p> <b>Delinquent / Past due</b></p>
        </div>
      </div>

      <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
        <div className="flex-grow-1 ms-3 m-auto">
        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
          <p><b>Various MIS reports</b></p>
        </div>
      </div>

      <div className="col-10 col-md-4 mx-auto text-center box-sn mt-4">
        <div className="flex-grow-1 ms-3 m-auto">
        <img src="Assets/Images/correct.gif" alt="..." style={{ width: '40px' }} />
          <p><b>Dashboards</b></p>
        </div>
      </div>


    </div>
    {/* <!--end of row key features--> */}


    <div className="row" id="Brochure">
      <div className="col-12 charchole py-5">
        <h4 className="text-center white">
          <b>
            "To acquire further information regarding the product."
          </b>
        </h4>
        <p className="white  text-center py-3">
          Explore the following details and specifications.
        </p>

        <div className="py-4 text-center">
          <a onClick={()=> window.open('http://cdat.co.in/doc/Nursing_Home_Application.pdf')}
        //   href="http://cdat.co.in/doc/Nursing_Home_Application.pdf"
            className="shrink-border text-decoration-none" target="_blank">Explore Brochure</a>

        </div>
      </div>
    </div>
    {/* <!--end of Brochure row--> */}



            <Footer />

        </>
    )
}